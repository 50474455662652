import React, { useState } from 'react';
import axios from 'axios';
import styles from './Home.module.css';
import Button from '@mui/material/Button';
import AlertModal from './AlertModal';
import CircularProgressWithLabel from './CircularProgressWithLabel';

const Home = () => {
  const [file, setFile] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [note, setNote] = useState('');
  const [fileMessage, setFileMessage] = useState('');
  const [audioMessage, setAudioMessage] = useState('');
  const [noteMessage, setNoteMessage] = useState('');
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalError, setModalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleStartRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        const chunks = [];

        recorder.ondataavailable = e => {
          chunks.push(e.data);
        };

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'audio/mp3' });
          setAudioBlob(blob);
        };

        recorder.start();
        setRecording(true);
      })
      .catch(err => {
        console.error('Ses Kaydedilemedi', err);
        showModal('Ses kaydedilemedi', true);
      });
  };

  const handleStopRecording = () => {
    mediaRecorder.stop();
    setRecording(false);
  };

  const handleSaveNote = async () => {
    if (!note) {
      setNoteMessage('Lütfen bir şeyler yazınız.');
      return;
    }

    const formData = new FormData();
    formData.append('file', new Blob([note], { type: 'text/plain' }), 'note.txt');

    try {
      const response = await axios.post('https://api.kitapbul.online/saveNote', formData);
      setNoteMessage('Notunuz Kaydedildi.');
      setNote(''); // Reset note field after successful save
    } catch (error) {
      setNoteMessage('Notunuz Kaydedilemedi.');
    }
  };

  const handleUploadFile = async () => {
    if (!file) {
      setFileMessage('Lütfen dosya seçiniz.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const response = await axios.post('https://api.kitapbul.online/upload', formData, {
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentCompleted = Math.floor((current / total) * 100);
          setProgress(percentCompleted);
        }
      });
      setFileMessage('Fotoğraf Paylaşıldı.');
      setFile(null); // Reset file field after successful upload
    } catch (error) {
      setFileMessage('Fotoğraf Paylaşılmadı.');
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const handleUploadAudio = async () => {
    if (!audioBlob) {
      setAudioMessage('Lütfen önce sesinizi kaydedin.');
      return;
    }

    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.mp3');

    try {
      const response = await axios.post('https://api.kitapbul.online/uploadAudio', formData);
      setAudioMessage('Mesajınız Kaydedildi.');
      setAudioBlob(null); // Reset audioBlob field after successful upload
    } catch (error) {
      setAudioMessage('Mesajınız Kaydedilmedi.');
    }
  };

  const showModal = (message, isError) => {
    setModalMessage(message);
    setModalError(isError);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <h4>Fotoğraf ve videolarınızı buradan paylaşabilirsiniz</h4>
        <input type="file" onChange={handleFileChange} />
        <Button variant="contained" className={styles.button} onClick={handleUploadFile}>
          Paylaş
        </Button>
        {loading && <CircularProgressWithLabel value={progress} />}
        {fileMessage && <AlertModal isOpen={true} message={fileMessage} isError={fileMessage.includes('Failed')} onClose={() => setFileMessage('')} />}
      </div>

      <div className={styles.section}>
        <h4>Sesli mesajınızı buradan paylaşabilirsiniz</h4>
        {!recording ? (
          <Button variant="contained" className={styles.button} onClick={handleStartRecording}>
            Kaydı Başlat
          </Button>
        ) : (
          <Button variant="contained" className={`${styles.button} ${styles.secondaryButton}`} onClick={handleStopRecording}>
            Kaydı Durdur
          </Button>
        )}
        <Button variant="contained" className={styles.button} onClick={handleUploadAudio} disabled={!audioBlob}>
          Mesajınızı Paylaşın
        </Button>
        {audioMessage && <AlertModal isOpen={true} message={audioMessage} isError={audioMessage.includes('Failed')} onClose={() => setAudioMessage('')} />}
      </div>

      <div className={styles.section}>
        <h4>Notunuzu buradan paylaşabilirsiniz</h4>
        <textarea rows="4" cols="50" value={note} onChange={(e) => setNote(e.target.value)} />
        <Button variant="contained" className={styles.button} onClick={handleSaveNote}>
          Paylaş
        </Button>
        {noteMessage && <AlertModal isOpen={true} message={noteMessage} isError={noteMessage.includes('Failed')} onClose={() => setNoteMessage('')} />}
      </div>

      <AlertModal isOpen={modalOpen} message={modalMessage} isError={modalError} onClose={closeModal} />
    </div>
  );
};

export default Home;
